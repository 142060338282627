<template>
  <div class="content">
      <div class="main">
          <div style="background: #fff;padding:10px">
              <a-table
                  :columns="columns"
                  :row-key="record => record.code"
                  :data-source="data"
                  :pagination="pagination"
                  :loading="loading"
                  @change="handleTableChange"
                  :scroll="{ y: tableHeight }"
              >
              </a-table>
          </div>
      </div>

  </div>
</template>

<script>
import { getConsultantList } from '@/api/admin'
export default {
  components: {
  },
  data () {
    return {
      params: {
        page: 1,
        per_page: 10
      },
      data: [],
      pagination: {
        total: 0,
        current: 1,
        showLessItems: true
      },
      loading: false,
      columns: [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '联系电话',
          dataIndex: 'phone'
        },
        {
          title: '地址',
          dataIndex: 'address'
        },
        {
          title: '公司名称',
          dataIndex: 'company'
        },
        {
          title: '提交时间',
          dataIndex: 'create_time'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      tableHeight: 0
    }
  },
  created () {
    this.getList()
  },
  mounted () {
    this.tableHeight = document.body.clientHeight - 330
  },
  methods: {
    getList () {
      this.loading = true
      this.params.page = this.pagination.current
      getConsultantList(this.params).then(res => {
        console.log(res, '踩踩踩踩踩踩踩踩')
        this.loading = false
        this.data = res.list
        this.pagination.total = res.count
      }).catch(err => {
        this.loading = false
        this.$message.error(err.msg)
      })
    },
    handleTableChange (e) {
      this.pagination.current = e.current
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {

  .main {
      padding: 15px;
      // padding-top: 90px;
  }
}
</style>
