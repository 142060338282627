<template>
  <div class="recruit">
    <div class="header">
      <a-button type="primary" @click="add">添加</a-button>
    </div>

    <div class="content">
      <a-card v-for="item in list" :key="item.code" class="ad-card">
        <div slot="title" class="ad-card-header">
          <span v-if="item.status === '0'" class="active-text">已开启</span>
          <a-switch
            v-if="editItem.code !== item.code"
            :default-checked="item.status === '0'"
            size="small"
            :loading="loadingStatus === item.code"
            @change="status => changeStatus(status, item)"
          />
          <span v-if="item.status === '1'" class="close-text">已关闭</span>
        </div>
        <div slot="extra" v-if="editItem.code !== item.code">
          <a-button type="link" @click="edit(item)">编辑</a-button>
          <a-popconfirm
            title="确定要删除该职位?"
            ok-text="删除"
            cancel-text="我在想想"
            @confirm="remove(item.code)"
          >
            <a-button type="link" :loading="loadingRemove === item.code" style="color:red">删除</a-button>
          </a-popconfirm>
        </div>
        <div slot="actions" v-if="item.code">该职位由 {{ item.create_by }} 创建于 {{ item.create_time }}</div>

        <a-form-model
          v-if="editItem.code === item.code"
          ref="ruleForm"
          :model="item"
          :rules="rules"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 8 }"
        >
          <a-form-model-item prop="name" label="职位名称">
            <a-input v-model="item.name" placeholder="最多不超过20个字" :max-length="20" />
          </a-form-model-item>

          <a-form-model-item prop="city" label="工作城市">
            <a-input v-model="item.city" placeholder="最多不超过20个字" :max-length="20" />
          </a-form-model-item>

          <a-form-model-item label="薪资" prop="min_salary" required>
            <div style="display:flex">
              <a-input
                v-model.number="item.min_salary"
                placeholder="请输入数字"
                style="margin-right: 20px;"
                type="number"
              />
              <a-input v-model.number="item.max_salary" placeholder="请输入数字" type="number" />
            </div>
          </a-form-model-item>

          <a-form-model-item label="招聘人数" prop="number">
            <a-input
              v-model="item.number"
              placeholder="请输入数字，最多不超过9999"
              type="number"
              :max-length="4"
            />
          </a-form-model-item>

          <a-form-model-item label="岗位职责" prop="introduce">
            <a-input
              v-model="item.introduce"
              placeholder="最多不超过1000字"
              type="textarea"
              :max-length="1000"
            />
          </a-form-model-item>

          <a-form-model-item label="状态" prop="status">
            <a-radio-group name="status" v-model="item.status">
              <a-radio value="0">正常</a-radio>
              <a-radio value="1">关闭</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button @click="cancelEdit">取消</a-button>
            <a-button type="primary" style="margin-left: 10px;" @click="onSubmit" :loading="loadingEdit">保存</a-button>
          </a-form-model-item>
        </a-form-model>

        <div v-else>
          <p class="name">{{ item.name }}</p>
          <div class="info">
            <a-tooltip>
              <span slot="title">工作城市</span>
              <div class="info-item">
                <a-icon type="environment" class="icon" />
                <span>{{ item.city }}</span>
              </div>
            </a-tooltip>

            <a-tooltip>
              <span slot="title">薪资范围</span>
              <div class="info-item">
                <a-icon type="pay-circle" class="icon" />
                <span>{{ item.min_salary }}k-{{ item.max_salary }}k</span>
              </div>
            </a-tooltip>

            <a-tooltip>
              <span slot="title">招聘人数</span>
              <div class="info-item">
                <a-icon type="team" class="icon" />
                <span>{{ item.number }}</span>
              </div>
            </a-tooltip>
          </div>

          <div>
            <p style="font-weight: bold;padding: 5px 0">岗位描述：</p>
            <pre v-html="item.introduce"></pre>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { getRecruitList, removeRecruit, addRecruit, updateRecruit } from '@/api/admin'
import { Card, Tooltip } from 'ant-design-vue'
import { getUserInfo } from '@/utils/auth'

export default {
  components: {
    [Card.name]: Card,
    [Card.Grid.name]: Card.Grid,
    [Tooltip.name]: Tooltip
  },
  data () {
    return {
      list: [],
      rules: {
        name: [
          { required: true, message: '请填写职位名称', trigger: 'change' }
        ],
        city: [{ required: true, message: '请填写工作城市', trigger: 'change' }],
        number: [
          // { type: Number, max: 9999, message: '最多不超过9999', trigger: 'change' },
          { required: true, message: '请填写招聘人数', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (value > 9999) {
                return callback(new Error('最多不超过9999'))
              }

              callback()
            },
            trigger: 'change'
          }
        ],
        min_salary: [{
          validator: (rule, value, callback) => {
            if (!this.editItem.min_salary || !this.editItem.max_salary) {
              return callback(new Error('请完善薪资范围'))
            }

            if (this.editItem.max_salary <= this.editItem.min_salary) {
              return callback(new Error('最高薪资必须大于起始薪资'))
            }

            callback()
          },
          trigger: 'change'
        }],
        introduce: [
          { required: true, message: '请填写岗位职责', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ]
      },
      loadingStatus: '',
      editItem: {},
      loadingEdit: false,
      loadingRemove: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      getRecruitList({
        page: 1,
        per_page: 10,
        type: '0'
      }).then(res => {
        this.list = res.list
      })
    },
    cancelEdit () {
      this.editItem = {}
      this.getList()
    },
    onSubmit () {
      this.$refs.ruleForm[0].validate(valid => {
        if (valid) {
          this.loadingEdit = true
          if (this.editItem.code) {
            updateRecruit({
              name: this.editItem.name,
              introduce: this.editItem.introduce,
              city: this.editItem.city,
              min_salary: this.editItem.min_salary.toString(),
              max_salary: this.editItem.max_salary.toString(),
              number: this.editItem.number,
              status: this.editItem.status,
              code: this.editItem.code
            }).then(res => {
              this.loadingEdit = false
              this.editItem = {}
              this.$message.success('职位信息已保存')
              this.getList()
            }).catch(err => {
              this.loadingEdit = false
              this.$message.error(err.msg)
            })
          } else {
            addRecruit({
              name: this.editItem.name,
              introduce: this.editItem.introduce,
              city: this.editItem.city,
              min_salary: this.editItem.min_salary.toString(),
              max_salary: this.editItem.max_salary.toString(),
              number: this.editItem.number,
              status: this.editItem.status,
              code: this.editItem.code,
              create_by: getUserInfo()
            }).then(res => {
              this.loadingEdit = false
              this.editItem = {}
              this.$message.success('职位信息已保存')
              this.getList()
            }).catch(err => {
              this.loadingEdit = false
              this.$message.error(err.msg)
            })
          }
        }
      })
    },
    changeStatus (status, item) {
      this.loadingStatus = item.code
      updateRecruit({
        name: item.name,
        introduce: item.introduce,
        city: item.city,
        min_salary: item.min_salary.toString(),
        max_salary: item.max_salary.toString(),
        number: item.number,
        status: status ? '0' : '1',
        code: item.code
      }).then(res => {
        this.loadingStatus = ''
        item.status = status ? '0' : '1'
      }).catch(err => {
        this.loadingStatus = ''
        this.$message.error(err.msg)
      })
    },
    remove (code) {
      this.loadingRemove = code
      removeRecruit({
        code
      }).then(res => {
        this.loadingRemove = ''
        this.getList()
      }).catch(err => {
        this.loadingRemove = ''
        this.$message.error(err.msg)
      })
    },
    edit (item) {
      this.editItem = item
    },
    add () {
      if (this.editItem.code) {
        return this.$message.warning('当前还有职位正在编辑')
      }
      this.editItem = {
        name: '',
        introduce: '',
        city: '',
        min_salary: '',
        max_salary: '',
        number: '',
        status: '0',
        create_by: ''
      }
      this.list.unshift(this.editItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.recruit {
  .header {
    position: fixed;
    background: #fff;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    z-index: 1;
  }

  .content {
    padding: 30px;
    padding-top: 90px;

    .ad-card {
      margin-bottom: 20px;

      .ad-card-header {
        display: flex;
        align-items: center;
        line-height: 1;
      }

      .active-text {
        padding-right: 10px;
        color: green;
      }

      .close-text {
        padding-left: 10px;
        color: #999;
      }

      .name {
        font-size: 16px;
        font-weight: bold;
      }

      .info {
        padding: 10px 0;
        display: flex;

        .info-item {
          padding-right: 20px;
          display: flex;
          align-items: center;
          line-height: 1;

          .icon {
            font-size: 14px;
            margin-right: 2px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>
